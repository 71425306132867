// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-name, .project-sum {
  height: 40px;
}

.brigade-name, .brigade-sum {
  height: 40px;
}

.project-name {
  cursor: pointer;
}

.brigade-name {
  margin-top: 20px;
}

.brigade-sum {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Distribution/style.scss"],"names":[],"mappings":"AAEA;EACI,YAAA;AADJ;;AAIA;EACG,YAAA;AADH;;AAIA;EACI,eAAA;AADJ;;AAIA;EACI,gBAAA;AADJ;;AAKA;EACI,gBAAA;AAFJ","sourcesContent":["\n\n.project-name, .project-sum {\n    height: 40px;\n}\n\n.brigade-name, .brigade-sum {\n   height: 40px;\n}\n\n.project-name {\n    cursor: pointer;\n}\n\n.brigade-name {\n    margin-top: 20px;\n  \n}\n\n.brigade-sum {\n    margin-top: 20px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
