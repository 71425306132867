// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.installation-days {
  margin-top: 15px;
}
.installation-days__month {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
.installation-days__month-arrow img {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.installation-days__month-name {
  font-size: 18px;
  font-weight: 400;
  padding: 0px 7px 0px 7px;
}
.installation-days__period {
  display: flex;
  justify-content: center;
}
@media (max-width: 450px) {
  .installation-days__period {
    margin-top: 0px;
  }
}
.installation-days__period-input {
  height: 30px;
  margin-right: 10px;
}
@media (max-width: 450px) {
  .installation-days__period-input {
    width: 150px;
  }
}
@media (max-width: 380px) {
  .installation-days__period-input {
    width: 120px;
  }
}
.installation-days__sumperiod, .installation-days__total-earnings {
  font-size: 18px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/InstallationPage/InstallationDays/style.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAGI;EACI,gBAAA;EACA,aAAA;EACA,uBAAA;AADR;AAIY;EACI,WAAA;EACA,YAAA;EACA,eAAA;AAFhB;AAMQ;EACI,eAAA;EACA,gBAAA;EACA,wBAAA;AAJZ;AAQI;EACI,aAAA;EACA,uBAAA;AANR;AAOQ;EAHJ;IAIQ,eAAA;EAJV;AACF;AAMQ;EACI,YAAA;EACA,kBAAA;AAJZ;AAKY;EAHJ;IAIQ,YAAA;EAFd;AACF;AAGY;EANJ;IAOQ,YAAA;EAAd;AACF;AAII;EACI,eAAA;EACA,gBAAA;AAFR","sourcesContent":[".installation-days {\n    margin-top: 15px;\n\n   \n\n    &__month {\n        margin-top: 15px;\n        display: flex;\n        justify-content: center;\n\n        &-arrow {\n            img {\n                width: 25px;\n                height: 25px;\n                cursor: pointer;\n            }\n        }\n\n        &-name {\n            font-size: 18px;\n            font-weight: 400;\n            padding: 0px 7px 0px 7px;\n        }\n    }\n\n    &__period {\n        display: flex;\n        justify-content: center;\n        @media(max-width: 450px) {\n            margin-top: 0px;\n        }\n\n        &-input {\n            height: 30px;\n            margin-right: 10px;\n            @media(max-width: 450px) {\n                width: 150px;\n            }\n            @media(max-width: 380px) {\n                width: 120px;\n            }\n        }\n    }\n\n    &__sumperiod, &__total-earnings {\n        font-size: 18px;\n        font-weight: 500;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
